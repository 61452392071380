<template>
  <div id="process-sheet">
    <Sheet
      v-model="processSheet"
      :width="formWidth"
      :has-footer="!isCustomizedWorkflow || !initiateProcess"
      no-padding
      @input="closeProcessSheet"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions>
        <template
          v-if="!isCustomizedWorkflow && workflow.initiatedBy !== 'DOCUMENT'"
        >
          <template v-if="mandatory.filledValue || mandatory.nonFilledValue">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="mandatoryFilling"
              size="lg"
              :thickness="0.22"
              color="primary"
              track-color="grey-3"
              class="q-mr-sm"
            >
              {{ mandatoryFilling }}%
            </q-circular-progress>
          </template>
          <div v-if="mandatory.filledValue" class="chip-success q-mr-sm">
            <div class="">{{ mandatory.filledValue }} ready</div>
          </div>
          <div
            v-if="mandatory.nonFilledValue"
            class="chip-warning"
            @click="checkFormValidation"
          >
            <div class="">{{ mandatory.nonFilledValue }} need to work</div>
          </div>
        </template>
        <template v-if="mandatoryDocument.length">
          <div
            class="chip-warning cursor-pointer"
            @click="attachmentSheet = true"
          >
            <div class="">{{ mandatoryDocument.length }} document required</div>
          </div>
        </template>
        <BaseActionButton
          v-if="itemModel.itemId"
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="processDocumentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
        <BaseActionButton
          v-if="!formView && !initiateProcess && actions.length === 0"
          v-tooltip.top="'Form View'"
          is-flat
          :icon="'mdi-form-select'"
          class="q q-mr-sm"
          @click="formView = true"
        />

        <BaseActionButton
          v-else-if="!initiateProcess && actions.length === 0"
          v-tooltip.top="'Details View'"
          is-flat
          :icon="'mdi-table-column'"
          class="q-mr-sm"
          @click="formView = false"
        />
        <BaseActionButton
          v-if="!isCustomizedWorkflow"
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div v-if="formId" class="col">
          <!-- form -->
          <BaseScrollbar height="calc(100vh - 115px)">
            <div v-if="formView" id="form-view">
              <RenderForm
                v-if="formId && Object.keys(form).length"
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
                :form-model="formModel"
                :save-action.sync="saveAction"
                :workflow-form-model.sync="workflowFormModel"
                :workflow-model.sync="workflowModel"
                :is-readonly="formEditAccess"
                :form-edit-controls="formEditControls"
                :readonly-controls="readonlyControls"
                :form-visibility-access="formVisibilityAccess"
                :form-secure-controls="formSecureControls"
                :enable-controls="_enableControls"
                :check-list.sync="checkList"
                :form-fields="formFields"
                :data-validation-values="dataValidationValues"
                :form-check-list.sync="formCheckList"
                :form-upload-files="formUploadFiles"
                :has-check-form-validate.sync="hasCheckFormValidate"
                :has-change-table-field="hasChangeTableField"
                @uploadFiles="getOCRResult"
                @mandatoryControlsList="mandatoryControlsList"
                @deletedFiles="deletedFiles"
                @showQr="generateQrCode"
              />

              <div
                v-if="signList.length"
                id="approvalSignature"
                class="q-pa-md"
              >
                <div class="title q-mb-sm">Approval Signature</div>

                <BaseSeparator class="q-my-sm" />

                <div class="row">
                  <template v-for="sign in signList">
                    <div :key="sign.id" class="col-3 q-mb-sm">
                      <img class="imgCenter col" :src="signLink(sign.id)" />
                      <div class="text row">
                        <span class="col-12 text-bold">{{ sign.stage }}</span>
                        <span class="col-12">{{ sign.createdByEmail }}</span>
                        <span class="col-12">{{
                          $day.format(sign.createdAt)
                        }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <SignatureField
                v-if="userSignature"
                v-model="signImg"
                label="Signature"
                class="q-pa-md"
              />
            </div>
            <div v-else class="q-pa-md">
              <!-- <div v-else class="col-12 q-mb-sm q-pt-md stepper">
                <Stepper
                  :stage-level="stageLevel"
                  :current-step="currentStep"
                />
              </div> -->

              <div
                v-if="requestFormData.length"
                id="requestDetails"
                class="col-12"
              >
                <div class="row justify-center">
                  <div class="col">
                    <div class="row items-center">
                      <table class="submittedData q-mb-sm">
                        <tbody>
                          <tr v-if="requestFormData[0].value[0].createdByName">
                            <td colspan="4">
                              Requested By:
                              {{ requestFormData[0].value[0].createdByName }}
                            </td>
                          </tr>
                          <tr>
                            <th v-if="requestFormData[0].value[0].raisedBy">
                              Requested User Email
                            </th>
                            <th>Requested Date</th>
                            <th>Last Acted By</th>
                            <th>Last Acted On</th>
                          </tr>
                          <tr>
                            <td v-if="requestFormData[0].value[0].raisedBy">
                              {{ requestFormData[0].value[0].raisedBy }}
                            </td>
                            <td>
                              {{
                                $day.format(
                                  requestFormData[0].value[0].raisedAt
                                )
                              }}
                            </td>
                            <td>
                              {{
                                requestFormData[0].value[0]
                                  .transaction_createdByEmail
                              }}
                            </td>
                            <td>
                              {{
                                $day.format(
                                  requestFormData[0].value[0]
                                    .transaction_createdAt
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <BaseScrollbar height="calc(100vh - 275px)">
                        <template>
                          <table class="mainData col-12">
                            <template
                              v-for="(value, key) in requestFormData[0].value[0]
                                .formData.fields"
                            >
                              <template v-if="value && value != '[]'">
                                <template v-if="validateType(key) !== 'TABLE'">
                                  <tr :key="`${key}-${value}`">
                                    <td class="tblHeader ellipsis small">
                                      {{ getLabel(key) }}
                                    </td>
                                    <td class="ellipsis">
                                      <template
                                        v-if="
                                          validateType(key) === 'FILE_UPLOAD'
                                        "
                                      >
                                        <div v-if="JSON.parse(value).length">
                                          <div
                                            v-for="file in JSON.parse(value)"
                                            :key="file.fileName"
                                            class="q-mb-xs"
                                          >
                                            <div class="row items-center">
                                              <div
                                                class="col row items-center"
                                                @click="viewFile(file, value)"
                                              >
                                                <FileIcon
                                                  :mime-type="
                                                    fileType(file.fileName)
                                                  "
                                                  class="mini-avatar q-mr-sm"
                                                />
                                                <span
                                                  class="cursor-pointer text-underline text-secondary"
                                                  >{{ file.fileName }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                      <template
                                        v-else-if="validateType(key) === 'DATE'"
                                      >
                                        {{ $day.format(value, false) }}
                                      </template>
                                      <template
                                        v-else-if="validateValue(value)"
                                      >
                                        {{ removeTags(value) || "-" }}
                                      </template>
                                      <template v-else>
                                        <a :href="value" target="_blank">
                                          {{ value || "-" }}
                                        </a>
                                      </template>
                                    </td>
                                  </tr>
                                </template>
                                <template v-else-if="JSON.parse(value).length">
                                  <tr
                                    v-if="getLabel(key)"
                                    :key="'trlabel-' + key"
                                  >
                                    <td
                                      colspan="2"
                                      class="tblHeader ellipsis small"
                                    >
                                      <FormFieldLabel
                                        :key="'label-' + key"
                                        :label="getLabel(key)"
                                        class="q-mt-sm"
                                      />
                                    </td>
                                  </tr>
                                  <tr :key="'trtable1-' + key">
                                    <td colspan="2">
                                      <BaseScrollbar
                                        :key="'scroll-' + key"
                                        style="max-height: 200px"
                                      >
                                        <table
                                          :key="'table-' + key"
                                          class="sub-table q-mt-sm q-mb-sm"
                                        >
                                          <thead>
                                            <tr>
                                              <th
                                                v-for="(
                                                  field_item, field_key
                                                ) in tableValue(value)[0]"
                                                :key="field_key"
                                              >
                                                {{
                                                  getLabel(field_key, "table")
                                                }}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="(
                                                field_item, field_key
                                              ) in tableValue(value)"
                                              :key="field_key"
                                            >
                                              <td
                                                v-for="(
                                                  row_value, row_key
                                                ) in field_item"
                                                :key="row_key"
                                              >
                                                {{ row_value }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </BaseScrollbar>
                                    </td>
                                  </tr>
                                </template>
                              </template>
                            </template>
                          </table>
                        </template>
                      </BaseScrollbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BaseScrollbar>
        </div>

        <div v-if="itemModel.itemId" class="col">
          <div class="fileFrame">
            <div class="file-list">
              <iframe
                id="file-frame"
                ref="myProcessFiles"
                :src="itemLink()"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
        <div
          v-if="formId || itemModel.itemId"
          class="col-auto q-mr-sm leftBorder"
        >
          <template v-for="tab in tabs">
            <BaseActionButton
              v-if="checkTabAccess()"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- footer -->
      <template #footer>
        <div v-if="actions.length" class="footer row">
          <BaseButton
            v-if="
              selectedNode === 'inbox' ||
              selectedNode === 'common' ||
              selectedNode === 'payment' ||
              initiateProcess
            "
            label="Save"
            class="q-ml-md"
            color="secondary"
            @click="validateRequest('Save')"
          />
          <template v-for="action in actions">
            <BaseButton
              v-if="
                action === 'Approve' ||
                action === 'Complete' ||
                action === 'Rightsize'
              "
              :key="action"
              :label="action"
              color="positive"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="
                action === 'Reject' ||
                action === 'Terminate' ||
                action === 'Force Close' ||
                action === 'Decline'
              "
              :key="action"
              :label="action"
              color="red"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="
                action === 'Forward' ||
                action.indexOf('Reply') > -1 ||
                action === 'Rollback' ||
                action === 'Assign'
              "
              :key="action"
              :label="action"
              color="orange"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="action === 'To Requester'"
              :key="action"
              :label="action"
              color="pink"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else
              :key="action"
              :label="action"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
          </template>
        </div>
      </template>
      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="
        selectedProcess.processId && attachmentList.length ? true : false
      "
      :upload="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment' ||
        initiateProcess
      "
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 15 seconds
        </div>
        <BaseActionButton
          v-if="selectedProcess.processId"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <template
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment' ||
          initiateProcess
        "
        #upload
      >
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template
        v-if="selectedProcess.processId && attachmentList.length"
        #subActions
      >
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents (only PDF)'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            selectedNode === 'inbox' ||
            selectedNode === 'common' ||
            selectedNode === 'payment'
              ? 'calc(100vh - 200px)'
              : ''
          "
        >
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="selectedProcess.processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                        <template v-else>
                          <BaseIcon
                            :name="
                              file.checked
                                ? 'eva-checkmark-circle-2'
                                : 'eva-radio-button-off-outline'
                            "
                            color="secondary"
                            class="q-mr-sm"
                            @click="file.checked = !file.checked"
                          />
                        </template>
                      </div>
                      <div v-else>
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                      </div>

                      <!-- <div v-if="file.formJsonId" class="col-auto">
                        <BaseIcon
                          v-tooltip:green.top="getLabel(file.formJsonId)"
                          name="mdi-form-select"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div> -->

                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div v-if="file.formJsonId" class="col-auto">
                        <q-chip
                          outline
                          color="green"
                          class="cursor-pointer green-hover"
                        >
                          {{ getLabel(file.formJsonId) }}
                        </q-chip>
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Download'"
                          icon="eva-download-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Download')"
                        />
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Print'"
                          icon="eva-printer-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Print')"
                        />
                      </div>
                    </div>

                    <div class="meta row">
                      <div class="col row">
                        <!-- created at -->

                        <div>{{ $day.format(file.createdAt) }}</div>

                        <!-- ... -->

                        <!-- created by -->

                        <template v-if="file.createdBy">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ file.createdByEmail }}</div>
                        </template>

                        <!-- ... -->

                        <!-- size -->

                        <template v-if="file.size">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ fileSize(file.size) }}</div>
                        </template>

                        <!-- ... -->
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Metadata'"
                          icon="eva-file-text-outline"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="getFileData(file)"
                        />
                      </div>
                      <template v-if="false">
                        <template
                          v-if="
                            selectedNode === 'inbox' ||
                            selectedNode === 'common' ||
                            selectedNode === 'payment' ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            ((selectedNode === 'inbox' ||
                              selectedNode === 'common' ||
                              selectedNode === 'payment') &&
                              file.createdBy === $store.state.session.id) ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <template v-if="file.loading">
                    <BaseIcon
                      name="mdi-loading"
                      color="secondary"
                      class="q-mr-sm loading"
                    />
                  </template>
                  <div v-if="selectedProcess.processId" class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->

                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <!-- <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div> -->
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>

                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div v-if="file.formJsonId" class="row q-mb-xs">
                  <div class="col">
                    <div class="row">
                      <div class="col-auto ellipsis attachment-label">
                        {{ getLabel(file.formJsonId) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFileData(file)"
                    />
                  </div>
                  <div
                    v-if="
                      ((selectedNode === 'inbox' ||
                        selectedNode === 'common' ||
                        selectedNode === 'payment') &&
                        file.createdBy === $store.state.session.id) ||
                      initiateProcess
                    "
                    class="col-auto"
                  >
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="eva-trash-2-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="attachmentDelete(file)"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <template v-if="file.size">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ fileSize(file.size) }}</div>
                    </template>

                    <!-- ... -->
                  </div>
                  <template v-if="!file.formJsonId">
                    <div class="col-auto">
                      <BaseActionButton
                        v-tooltip.top="'Metadata'"
                        icon="eva-file-text-outline"
                        color="secondary"
                        no-border
                        is-flat
                        size="20px"
                        @click.stop="getFileData(file)"
                      />
                    </div>
                    <div
                      v-if="
                        ((selectedNode === 'inbox' ||
                          selectedNode === 'common' ||
                          selectedNode === 'payment') &&
                          file.createdBy === $store.state.session.id) ||
                        initiateProcess
                      "
                      class="col-auto"
                    >
                      <BaseActionButton
                        v-tooltip.top="'delete'"
                        icon="eva-trash-2-outline"
                        color="red"
                        no-border
                        is-flat
                        size="20px"
                        @click.stop="attachmentDelete(file)"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      :custom-height="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment'
          ? '30'
          : '0'
      "
      no-padding
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments(0)"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar :height="commentsSheetHeight">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            >
              <template v-slot:avatar>
                <div
                  v-if="row.embedFileList && row.embedFileList.length"
                  class="file-icons"
                >
                  <FileIcon
                    :key="`Icon-${idx}`"
                    v-tooltip.top="row.embedFileList[0].fileName"
                    :mime-type="fileType(row.embedFileList[0].fileName)"
                    class="file-icon q-mr-sm"
                  />
                </div>
              </template>
            </q-chat-message>
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment'
        "
        class="col borderTop"
      >
        <div class="row">
          <template v-if="checkTenant() && !portal">
            <div class="col actions">
              <div
                v-if="attachmentList.length && fileNames.length"
                class="row select-action q-pa-sm action-container"
              >
                <CheckboxField
                  v-model="attachComment"
                  label="Add Comment to PDF"
                  class="col-auto q-pr-sm"
                />

                <SelectField
                  v-if="attachComment"
                  v-model="selectedFileForAttach"
                  :is-searchable="true"
                  :options="fileNames"
                  class="col field comment-attach attach-cmd"
                />
              </div>
              <SingleChoiceField
                v-if="displayInternalPublic"
                v-model="commentAction"
                :options="commentActions"
                :show-options-wrapper="false"
                :options-per-line="2"
                class="action-container"
                :class="{
                  'internal-public': attachmentList.length > 0,
                  'col q-pa-sm': true,
                  'internal-public-margin': attachComment,
                }"
              />
            </div>
          </template>
          <template v-if="isTenantGOTOComments()">
            <SingleChoiceField
              v-model="jiraCommentAction"
              :options="jiraCommentActions"
              :options-per-line="2"
              class="col q-mb-sm actions"
            />
          </template>
        </div>
        <div class="row text-area-action q-ml-sm q-mr-sm">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto; margin-right: 8px">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm comment-actions"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm comment-actions"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      v-model="mailTransactionSheet"
      width="30vw"
      no-padding
      @input="mailTransactionSheet = false"
    >
      <!-- title -->

      <template #title> Mail Transactions </template>

      <!-- ... -->

      <template #actions>
        <div class="q-ml-sm text-gray q-mr-sm ellipsis" style="font-size: 10px">
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getMailTransactions()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <div class="q-pl-sm">
          <Tabs v-model="mailTab" :tabs="mailTabs" />
        </div>
        <template v-if="mailTab === 'OUTGOING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in outgoingMails">
              <div :key="'outgoing' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>

                    <BaseActionButton
                      v-tooltip.top="'resend'"
                      icon="mdi-send"
                      color="secondary"
                      is-flat
                      size="16px"
                      @click.stop="resendMail(row.ezMailId)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
        <template v-if="mailTab === 'INCOMING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in incomingMails">
              <div :key="'incoming' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
      </div>

      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="mailAttachmentSheet"
      width="30vw"
      no-padding
      @input="mailAttachmentSheet = false"
    >
      <!-- title -->

      <template #title> Mail Attachments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <template v-for="file in mailAttachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openAttachmentFile(file)">
                  {{ file.name }}
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          advancedCommentsSheet = false;
        }
      "
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template v-if="checkTenant() && !portal">
          <div class="row actions advance-cmd">
            <template v-if="attachmentList.length && fileNames.length">
              <CheckboxField
                v-model="attachComment"
                label="Add Comment to PDF"
                class="col-auto q-pr-sm"
                style="margin: auto"
              />

              <SelectField
                v-if="attachComment"
                v-model="selectedFileForAttach"
                :is-searchable="true"
                :options="fileNames"
                class="field comment-attach"
                @input="handleAttachComment"
              />
            </template>
          </div>
          <SingleChoiceField
            v-if="checkTenant() && displayInternalPublic"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <template v-if="isTenantGOTOComments()">
          <SingleChoiceField
            v-model="jiraCommentAction"
            :options="jiraCommentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </template>
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Modal
      v-model="processForwardModal"
      width="40vw"
      height="60vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          processForwardModal = false;
        }
      "
    >
      <!-- title -->

      <template #title
        ><span v-if="!processForwardGroupAction"
          >"{{ selectedProcess.requestNo }}"</span
        >
        Process Forward</template
      >

      <!-- ... -->

      <template #default>
        <div id="processForward">
          <!-- user -->

          <MultiSelectField
            v-model="forwardUsers"
            label="users"
            :options="userList"
            class="field"
          />

          <!-- ... -->

          <!-- group -->

          <MultiSelectField
            v-model="forwardGroups"
            label="group"
            :options="groupList"
            class="field"
          />

          <!-- ... -->

          <TextAreaField
            v-if="processForwardGroupAction"
            v-model="commentText"
            placeholder="Type a comment..."
            class="field q-mt-md"
          />
        </div>
      </template>

      <template #footer>
        <template v-if="isTenantGOTO()">
          <BaseButton
            label="Assign"
            color="orange"
            @click="
              {
                processForwardModal = false;
                processForwardGroupAction
                  ? groupProcessRequest()
                  : processRequest(false, false);
              }
            "
          />
        </template>
        <template v-else>
          <BaseButton
            label="Forward"
            color="orange"
            @click="
              {
                processForwardModal = false;
                processForwardGroupAction
                  ? groupProcessRequest()
                  : processRequest(false, false);
              }
            "
          />
        </template>
      </template>
    </Modal>

    <Sheet
      v-model="jiraIssueSheet"
      width="40vw"
      has-footer
      @input="closeJiraIssueSheet"
    >
      <!-- title -->

      <template #title> JIRA - Create Issue </template>

      <!-- ... -->

      <template #default>
        <div id="content-wrapper" class="jiraIssue">
          <ValidationObserver ref="form">
            <div class="field-wrapper">
              <ValidationProvider
                v-slot="{ errors }"
                name="Issue Name"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueName"
                  label="Issue Name"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-if="false"
                v-slot="{ errors }"
                name="Project Key"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="projectKey"
                  label="Project Key"
                  is-mandatory
                  :error="errors[0]"
                  tooltip="Unique identifier key of your Project"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-if="false"
                v-slot="{ errors }"
                name="Issue Type"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueType"
                  label="Issue Type"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Description"
                :rules="{ required: true }"
              >
                <TextAreaField
                  v-model="issueDescription"
                  label="Description"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <!-- <ValidationProvider
                v-slot="{ errors }"
                name="Epic Value"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueEpicValue"
                  label="Epic Value"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider> -->

              <CheckboxField
                v-if="false"
                v-model="issueDescriptionTable"
                label="Include inbox request information as a table of issue description"
                class="field"
              />
              <!-- v-if="issueDescriptionTable" -->
              <MultiSelectField
                v-if="false"
                v-model="issueDescriptionFields"
                label="Request Fields"
                :is-searchable="true"
                :options="formFields"
                class="field"
              />

              <SelectField
                v-if="false"
                v-model="issueAssignee"
                label="Assignee"
                :is-searchable="true"
                :options="issueAssigneeUsers"
                class="field"
              />
            </div>
          </ValidationObserver>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Create Issue"
          color="primary"
          @click="createJiraIssue"
        />
      </template>
    </Sheet>

    <MetaDataEdit
      v-if="showMetaDataEditModal"
      :value="showMetaDataEditModal"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      :title="metaDataTitle || 'Index'"
      :columns="columns"
      :modal-value="workflowModel"
      :read-only="true"
      :upload-filename="uploadFilename"
      :meta-data-fields="metaDataModal"
      @close="closeMetaDataEditModal"
      @save="saveMetaData"
    />

    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="addTask()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="viewTask(task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <AddTask
      v-if="taskFormId"
      :value="addTaskSheet"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="taskFormId"
      :repository-id="taskRepositoryId"
      :task-model="selectedTask"
      :workflow-task="true"
      :mode="taskMode"
      @close="addTaskSheet = false"
      @addEntry="addTaskEntry"
    ></AddTask>

    <ImportPopup
      v-if="importExcel"
      :chip-button="true"
      :data-imported="dataImported"
      :form-id="String(masterFormId)"
      class="row items-center q-mr-xs"
      @upload="$refs.input.click()"
      @close="dataImported = false"
    />

    <input
      ref="input"
      type="file"
      accept=".csv,.xls,.xlsx"
      class="hidden"
      @change="uploadFile"
    />

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <!-- <p class="text-secondary">
            After submitting, you can upload the required documents from sent
            list once you have them.
          </p> -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="proceedSubmission" />
      </template>
    </Confirm>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="workflow.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>

    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <FormEntryHistory
      v-if="showFormEntryHistoryModal"
      :value.sync="showFormEntryHistoryModal"
      :workflow-id="workflow.id"
      :process-id="selectedProcess.processId"
    />
    <GenerateQr
      :value="showQr"
      :repository-id="workflow.repositoryId"
      :field-id="selectedFieldId"
      :field-name="selectedFieldName"
      @checkUploadId="checkMobileUpload"
      @expired="QRexpired"
      @input="showQr = false"
    />
    <Attachments
      v-if="showAttachmentSheet"
      :value.sync="showAttachmentSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="initiateProcess"
      :form-check-list="formCheckList"
      :check-list="checkList"
      :wform="wform"
      :repository-details="repositoryDetails"
      :workflow-model="workflowModel"
      :form-upload-files="formUploadFiles"
      :repository-field="repositoryField"
      :columns="columns"
      :attachments.sync="attachmentList"
      :workflow-id="workflow.id"
      :is-portal="portal"
      @attachmentCount="attachmentCount"
    />

    <Comments
      v-if="showCommentsSheet"
      :value.sync="showCommentsSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="initiateProcess"
      :attachment-list="attachmentList"
      :workflow-id="workflow.id"
      :file-names="fileNames"
      :comments.sync="commentsList"
      :display-internal-public="displayInternalPublic"
      :is-portal="portal"
      @commentsCount="commentsCount"
    />
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import {
  workflow,
  repository,
  form,
  integration,
  custom,
  menu,
  transaction,
  report,
  uploadAndIndex,
} from "@/api/factory.js";
import ImportPopup from "@/components/common/display/item-actions/components/ImportPopup.vue";

//   repository,
//   uploadAndIndex,
//   integration,
//   auth,
//   menu,
import SignatureField from "@/components/common/form/signature-field/SignatureField.vue";
import axios from "axios";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import formatBytes from "@/helpers/format-bytes.js";
import Modal from "@/components/common/popup/Modal.vue";
import ListItem from "@/components/common/ListItem.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";
import Confirm from "@/components/common/popup/Confirm.vue";
import EmailSharing from "@/components/common/EmailSharing.vue";
import FileDetails from "../FileDetails";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import { fileToBase64 } from "@/helpers/file-to-base64.js";
import { decrypt } from "@/helpers/crypto";
import { lowerCase, isEmpty } from "lodash-es";
// import Stepper from "@/components/common/workflow/Stepper.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormEntryHistory from "@/components/common/workflow/FormEntryHistory.vue";
import Attachments from "@/components/common/workflow/Attachments.vue";
import Comments from "@/components/common/workflow/Comments.vue";
import GenerateQr from "@/components/common/form/file-upload-field/GenerateQr.vue";

export default {
  name: "SubWorkflowProcess",

  components: {
    ValidationObserver,
    ValidationProvider,
    MultiSelectField,
    FileIcon,
    ListItem,
    Sheet,
    RenderForm,
    Confirm,
    SignatureField,
    Modal,
    MetaDataEdit,
    CheckboxField,
    TextAreaField,
    TextField,
    SingleChoiceField,
    TextBuilderField,
    Tabs,
    AddTask,
    ImportPopup,
    EmailSharing,
    FileDetails,
    ProcessHistory,
    // Stepper,
    FormFieldLabel,
    FormEntryHistory,
    Attachments,
    Comments,
    GenerateQr,
  },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },

    initiateSubWorkflowProcess: {
      type: Boolean,
      default: false,
    },

    mainWorkflowId: {
      type: Number,
      default: 0,
    },

    mainProcessId: {
      type: Number,
      default: 0,
    },

    subProcessLink: {
      type: String,
      default: "",
    },

    selectedProcess: {
      type: Object,
      default: () => {},
    },

    openSubProcess: {
      type: Boolean,
      default: false,
    },

    mainFormFields: {
      type: Array,
      default: () => [],
    },

    mainFormModel: {
      type: Object,
      default: () => {},
    },

    portal: {
      type: Boolean,
      default: false,
    },

    subProcessId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      workflow: {},
      workflowList: [],
      initiate: false,
      processSheet: false,
      headerTitle: "",
      formModel: {},
      form: {},
      formId: "",
      formData: "",
      actions: [],
      processActionsAddOn: [],
      workflowFormModel: {},
      saveAction: "",
      commentsList: [],
      attachmentList: [],
      initiateProcess: false,
      signList: [],
      formEditAccess: false,
      formEditControls: [],
      readonlyControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      repositoryDetails: {},
      repositoryField: [],
      formWidth: "100vw",
      checkList: [],
      stageLevel: [],
      currentStep: 0,
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      jiraCommentActions: [
        {
          id: this.$nano.id(),
          label: "This resource",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "All resource",
          value: 2,
        },
      ],
      jiraCommentAction: 1,
      formFields: [],
      checkListRowIndex: null,
      requestPrefix: "",
      mlPrediction: "",
      advancedCommentsSheet: false,
      advancedCommentText: "",
      docWarning: false,
      alertModal: false,
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionUserPassword: "",
      actionComments: false,
      actionConfirm: false,
      actionConfirmAlert: false,
      showMetaDataEditModal: false,
      selectedUploadFile: {},
      workflowModel: {},
      customVerify: false,
      formCheckList: false,
      reuploadFile: {},
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      scrollPosition: 0,
      commentsLoad: null,
      mailTransactionLoad: null,
      documentLoad: null,
      documentLoadList: [],
      uploadFilename: "",
      filterSearchColumn: {},
      selectedFilterColumn: "",
      watchViewerClose: null,
      saveOnly: false,
      wform: [],
      userSignature: false,
      dataValidationValues: [],
      processForwardModal: false,
      forwardUsers: [],
      forwardGroups: [],
      processForwardGroupAction: false,
      selectedFilter: [],
      filterBy: [],
      attachmentLoad: null,
      signImg: "",
      selectAll: false,
      emailSharingSheet: false,
      selectedFileList: [],
      fileDetailsSheet: false,
      fileData: [],
      jiraConnect: false,
      jiraAccounts: [],
      jiraIssueSheet: false,
      issueName: "",
      projectKey: "",
      issueType: "",
      issueDescription: "",
      issueEpicValue: "",
      issueAssignee: "",
      issueAssigneeUsers: [],
      issueDescriptionTable: false,
      issueDescriptionFields: [],
      jiraConfirmModal: false,
      issueRef: {},
      taskSheet: false,
      itemModel: {},
      errorMessage: "",
      errorModal: false,
      successModal: false,
      selectedNode: "",
      attachmentSheet: false,
      commentsSheet: false,
      // selectedProcess: {},
      processId: 0,
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "mail transaction",
          value: "MAIL",
          icon: "mdi-email-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Form Entry History",
          value: "FORM_ENTRY_HISTORY",
          icon: "mdi-file-document",
          count: 0,
        },
      ],
      taskEntries: [],
      addTaskEnabled: false,
      mailTab: "OUTGOING",
      mailTransactionSheet: false,
      commentText: "",
      mailTransactionList: [],
      mailAttachmentSheet: false,
      mailAttachmentList: [],
      fileSheet: false,
      selectedFile: {},
      mailTabs: [
        {
          id: this.$nano.id(),
          label: "outgoing",
          value: "OUTGOING",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "incoming",
          value: "INCOMING",
          count: 0,
        },
      ],
      userList: [],
      groupList: [],
      refreshTree: true,
      loadInitial: true,
      customReply: false,
      userReply: false,
      toRequester: false,
      customCloseProcessSheet: false,
      approvalTypes: [
        {
          id: this.$nano.id(),
          label: "Approve",
          value: "Approved",
        },
        {
          id: this.$nano.id(),
          label: "Cancel",
          value: "Cancelled",
        },
      ],
      activeViewLoad: null,
      hideColumns: [],
      columns: [],
      generatePDF: 0,
      documentRequired: false,
      hasSelectedProcess: 0,
      hasSelectedWorkflow: 0,
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      taskEntryId: [],
      taskEntryList: [],
      taskMode: "",
      selectedTask: {},
      importExcel: false,
      masterFormId: 0,
      dataImported: false,
      sameFormFields: [],
      historyProcessId: 0,
      attachComment: false,
      fileNames: [],
      selectedFileForAttach: 0,
      displayInternalPublic: false,
      formUploadFiles: [],
      mandatory: {},
      fileControlDeleteFile: [],
      hasCheckFormValidate: false,
      fileUploadForControl: false,
      fileUploadInput: {},
      metaDataUpdate: false,
      metaDataModal: {},
      metaDataTitle: "",
      requestFormData: [],
      formView: true,
      showFormEntryHistoryModal: false,
      showAttachmentSheet: false,
      showCommentsSheet: false,
      showQr: false,
      selectedFieldId: "",
      selectedFieldName: "",
      hasChangeTableField: {},
    };
  },

  computed: {
    formName() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.name;
    },

    formDescription() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.description;
    },

    panels() {
      if (!this.formId) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    selectedFileCount() {
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    isSelectedPDF() {
      let filePDF = true;
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() !== "pdf") {
          filePDF = false;
        }
      });
      return filePDF;
    },

    isMaximized() {
      return this.formWidth === "100vw";
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      if (this.panels) {
        this.panels.forEach((panel) => {
          if (panel.fields.length) {
            let panelFields = panel.fields.filter(
              (field) =>
                field.type === "SINGLE_SELECT" ||
                field.type === "SINGLE_CHOICE" ||
                field.type === "MULTIPLE_CHOICE" ||
                field.type === "MULTI_SELECT"
            );
            if (panelFields.length) {
              panelFields.forEach((row) => {
                if (row.settings.validation.enableSettings) {
                  if (row.settings.validation.enableSettings.length) {
                    let controls = [];
                    row.settings.validation.enableSettings.forEach((tblrow) => {
                      if (tblrow.controls.length) {
                        controls.push({
                          value: tblrow.value,
                          childControls: tblrow.controls,
                        });
                        tblrow.controls.forEach((control) => {
                          if (controls.indexOf(control) < 0) {
                            listControls.push(control);
                          }
                        });
                      }
                    });
                    if (controls.length) {
                      enableControls.push({
                        id: row.id,
                        parentControls: controls,
                      });
                    }
                  }
                }
              });
            }
          }
        });
      }

      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    isCustomizedWorkflow() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflow.id === 1014) {
          return true;
        }
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        if (this.workflow.id === 1) {
          return true;
        }
      }
      return false;
    },

    outgoingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Outgoing"
      );
    },

    incomingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Incoming"
      );
    },

    commentsSheetHeight() {
      if (
        this.selectedNode === "inbox" ||
        this.selectedNode === "payment" ||
        this.selectedNode === "common"
      ) {
        if (this.checkCommentsOption()) {
          if (this.attachmentList.length && this.fileNames.length) {
            if (this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 240px)";
            } else if (this.attachComment && !this.displayInternalPublic) {
              return "calc(100vh - 200px)";
            } else if (!this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 225px)";
            } else {
              return "calc(100vh - 190px)";
            }
          } else if (this.displayInternalPublic) {
            return "calc(100vh - 190px)";
          } else {
            return "calc(100vh - 150px)";
          }
        } else {
          return "calc(100vh - 150px)";
        }
      }
      return "";
    },

    mandatoryDocument() {
      return this.checkList.filter((row) => row.required && !row.attach);
    },

    mandatoryFilling() {
      if (this.mandatory.filledValue && this.mandatory.nonFilledValue) {
        return Math.floor(
          (this.mandatory.filledValue /
            (this.mandatory.filledValue + this.mandatory.nonFilledValue)) *
            100
        );
      } else if (this.mandatory.filledValue) {
        return 100;
      }
      return 0;
    },

    request() {
      return this.selectedProcess;
    },
  },

  watch: {
    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          if (this.initiateProcess) {
            this.checkRequiredDocument();
            //this.initiateRequest();
          } else {
            this.checkRequiredDocument();
          }
        }
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.selectedProcess.processId) {
          this.getAttachments();
          if (this.selectedProcess.flowStatus === "0") {
            if (this.attachmentList.length) {
              let index = this.attachmentList.length - 1;
              this.getFileData(this.attachmentList[index], "file");
            }
            this.attachmentLoad = setInterval(() => {
              this.getAttachments();
            }, 15000);
          }
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    commentsSheet: {
      immediate: true,
      handler() {
        if (
          this.commentsSheet &&
          this.selectedProcess.processId &&
          this.selectedProcess.flowStatus === "0"
        ) {
          this.commentsLoad = setInterval(() => {
            this.getComments(this.workflowId, this.selectedProcess.processId);
          }, 30000);
        } else {
          clearInterval(this.commentsLoad);
        }
      },
    },

    mailTransactionSheet: {
      immediate: true,
      handler() {
        if (this.mailTransactionSheet && this.selectedProcess.processId) {
          this.mailTransactionLoad = setInterval(() => {
            this.getMailTransactions();
          }, 30000);
        } else {
          clearInterval(this.mailTransactionLoad);
        }
      },
    },

    taskEntries: {
      immediate: true,
      handler() {
        this.tabs.find((tab) => {
          if (tab.value === "TASK") {
            tab.count = this.taskEntries.length;
          }
        });
      },
    },

    workflowId: {
      immediate: true,
      handler() {
        // this.getWorkflow();
      },
    },

    initiateSubWorkflowProcess: {
      immediate: true,
      handler() {
        if (this.initiateSubWorkflowProcess) {
          this.getWorkflow();
        }
      },
    },

    processSheet() {
      this.fileData = [];
      this.workflowModel = {};
      // if (this.selectedProcess.processId) {
      //   this.getAttachments();
      // }
    },

    dataValidationValues: {
      immediate: true,
      deep: true,
      handler() {
        this.customVerify = false;
        if (this.dataValidationValues.length) {
          let dataVerify = this.dataValidationValues.filter(
            (row) => row.status !== "Matched" && row.status !== "Verified"
          );
          if (dataVerify.length) {
            this.customVerify = true;
          }
        }
      },
    },

    customCloseProcessSheet: {
      deep: true,
      immediate: true,
      handler() {
        // this.closeProcessSheet();
      },
    },

    subProcessLink() {
      if (this.subProcessLink) {
        this.selectedNode = "sent";
        this.showLinks(this.subProcessLink);
      }
    },

    openSubProcess: {
      immediate: true,
      handler() {
        if (this.openSubProcess) {
          this.formId = "";
          this.formView = false;
          if (this.selectedProcess.inbox) {
            this.formView = true;
            this.getWorkflow();
          } else {
            this.getWorkflow("process");
          }
        }
      },
    },

    selectedProcess: {
      deep: true,
      handler() {
        if (this.selectedProcess.processId) {
          this.getAttachments(this.workflowId, this.selectedProcess.processId);
          this.getComments(this.workflowId, this.selectedProcess.processId);
        }
      },
    },

    workflowModel: {
      // immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        console.log(oldVal, newVal);

        if (isEmpty(oldVal)) {
          this.sameFormFields.forEach((item) => {
            // console.log(item.name in this.workflowModel, item.value);

            if (item.name in this.workflowModel) {
              if (item.value) {
                if (Array.isArray(item.value)) {
                  if (item.value.length) {
                    this.workflowModel[item.name] = [];
                    item.value.forEach((table) => {
                      this.workflowModel[item.name].push(table);
                    });
                    let table = JSON.parse(
                      JSON.stringify(this.workflowModel[item.name])
                    );
                    this.workflowModel[item.name] = table;

                    this.$set(this.hasChangeTableField, item.name, table);
                  }
                } else {
                  this.workflowModel[item.name] = item.value;
                }
              }
            }
          });
        }
      },
    },
  },

  created() {
    this.getTaskForm();
  },

  methods: {
    checkMobileUpload(id) {
      console.log(id);
      this.uploadInterval = setInterval(() => {
        this.watchMobileUpload(id);
      }, 5000);
    },

    QRexpired(id) {
      this.showQr = false;

      setTimeout(async () => {
        const { payload } = await repository.getQrFileUploadById(id);
        console.log(payload, "payload1");
        let jsonData = JSON.parse(payload.jsonData);
        if (!payload.status) {
          let parsedJsonData = jsonData[0];
          parsedJsonData = { ...parsedJsonData, expired: true };

          let input = {
            id: id,
            url: payload.url,
            jsonData: JSON.stringify([parsedJsonData]),
            status: 0,
          };
          const { error } = await repository.updateJson(id, input);

          if (error) {
            return;
          }

          clearInterval(this.uploadInterval);
          this.uploadInterval = null;
          this.$alert.info(`QR expired`);
        }
      }, 5000);
    },

    async watchMobileUpload(id) {
      const { error, payload } = await repository.getQrFileUploadById(id);
      console.log(error, payload, "error, payload");

      let jsonData = JSON.parse(payload.jsonData);
      console.log(jsonData, "jsonData", "payload.status", payload.status);
      if (payload.status) {
        let parsedJsonData = jsonData[0];
        console.log(parsedJsonData, "file");
        let updatedData = {
          name: parsedJsonData.attachmentList[0].name,
          size: parsedJsonData.attachmentList[0].size,
          file: "",
          fileId: parsedJsonData.attachmentList[0].id,
          id: parsedJsonData.attachmentList[0].id,
          fileControl: true,
          formAttach: true,
          createdBy: parsedJsonData.attachmentList[0].createdByEmail,
          createdAt: this.$day.format(
            parsedJsonData.attachmentList[0].createdAt
          ),
          workflowId: this.workflow.id,
          processId: this.selectedProcess.processId,
          fieldId: parsedJsonData.fieldId,
          fileDataValidate: "",
        };
        this.attachmentList.push(updatedData);
        // this.formUploadFiles.push(updatedData);

        let workflowModelValue = {
          name: parsedJsonData.attachmentList[0].name,
          size: parsedJsonData.attachmentList[0].size,
          file: "",
          fileId: parsedJsonData.attachmentList[0].id,
          uploadedPercentage: 100,
          createdBy: parsedJsonData.attachmentList[0].createdByEmail,
          createdAt: this.$day.format(
            parsedJsonData.attachmentList[0].createdAt
          ),
        };

        this.formFields.forEach((field) => {
          if (
            field.type === "FILE_UPLOAD" &&
            this.workflowModel[field.value] &&
            field.value == parsedJsonData.fieldId
          ) {
            this.workflowModel[field.value].push(workflowModelValue);
            // console.log( this.workflowModel[field.value],"this.workflowModel[field.value]")
          }
        });

        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });

        this.showQr = false;
        console.log(updatedData, "updatedData");
        clearInterval(this.uploadInterval);
        this.uploadInterval = null; //
      } else {
        console.log("Waiting for uploaded");
        // console.log(this.processSheet,"processSheet")
        if (!this.processSheet) {
          clearInterval(this.uploadInterval);
          this.uploadInterval = null; //
        }
      }
      // this.repositoryId = jsonData[0].repositoryId;
      // this.url =  jsonData[0].url;
    },
    async generateQrCode(fieldId) {
      this.selectedFieldId = fieldId;
      let name = await this.getLabel(fieldId);
      console.log(name, "name");

      this.selectedFieldName = name;
      console.log(fieldId, "fieldId");
      this.showQr = true;
    },
    isTenantArmgroup() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    checkTabAccess(value) {
      if (this.portal) {
        if (value === "ATTACHMENTS" || value === "COMMENTS") {
          return true;
        }
        return false;
      } else {
        if (value === "TASK") {
          return this.addTaskEnabled;
        } else if (value === "VALIDATION") {
          if (this.isTenantArmgroup()) {
            return true;
          } else {
            return false;
          }
        } else if (value === "FORM_ENTRY_HISTORY") {
          if (this.formId) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }
    },

    reset() {
      this.headerTitle = "";
      this.mandatory = {};
      this.actions = [];
      this.processActionsAddOn = [];
      this.form = {};
      this.formId = "";
      this.formFields = [];
      this.tabs.find((tab) => {
        tab.count = 0;
      });
      this.taskEntries = [];
      this.commentsList = [];
      this.attachmentList = [];
      this.initiateProcess = true;
      this.formModel = {};
      this.signList = [];
      this.formEditAccess = false;
      this.formEditControls = [];
      this.readonlyControls = [];
      this.formVisibilityAccess = true;
      this.formSecureControls = [];
      this.importExcel = false;
    },

    async getWorkflow(process) {
      this.reset();
      this.processSheet = true;
      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.getWorkflow(this.workflowId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflow = payload;
      if (process) {
        this.selectedNode = "sent";
      } else {
        this.selectedNode = "inbox";
      }
      if (this.selectedProcess.inbox) {
        await this.openProcess(false);
      } else {
        await this.openProcess(process ? false : true);
      }
      this.$store.commit("hideLoadingBarSave");
    },

    closeProcessSheet() {
      this.processSheet = false;
      this.saveAction = "";
      this.workflowFormModel = {};
      this.requestFormData = [];
      this.formView = true;
      this.$emit("closeProcessSheet");
      //   this.$emit("update:fullView", false);
    },

    getLabel(fieldId) {
      let controlLabel = "";
      if (Object.keys(this.form).length) {
        this.form.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          let field = panel.fields.find((field) => field.id === fieldId);
          if (field) {
            controlLabel = field.label ? field.label : field.type;
            return;
          }
        });
      }
      return controlLabel;
    },

    async openProcess(initiate = false) {
      this.getRepository();

      if (initiate) {
        // this.selectedProcess = {};

        let wflowJson = JSON.parse(this.workflow.flowJson);
        let startBlock = wflowJson.blocks.find(
          (block) => block.type === "START"
        );
        console.log(startBlock.settings.fileSettings);

        if (startBlock.settings.fileSettings) {
          if (startBlock.settings.fileSettings.fileCheckList.length) {
            this.checkList = startBlock.settings.fileSettings.fileCheckList;
            this.checkList.forEach((row) => {
              row.uid = this.$nano.id();
              row.attach = false;
            });
          }
        }
        if (startBlock) {
          if (startBlock.settings.initiateMode === "MANUAL") {
            this.headerTitle = `${startBlock.settings.label}`;
            let rules = wflowJson.rules.find(
              (rule) => rule.fromBlockId === startBlock.id
            );
            if (rules) {
              this.actions.push(rules.proceedAction);
            }
          } else if (startBlock.settings.initiateMode === "MANUAL_AUTOMATIC") {
            if (
              startBlock.settings.users.indexOf(this.$store.state.session.id) >
                -1 ||
              startBlock.settings.users.indexOf("0") > -1
            ) {
              if (startBlock.settings.initiateBy.indexOf("MASTER_FORM") > -1) {
                this.importExcel = true;
                //   this.$emit(
                //     "update:masterFormId",
                //     startBlock.settings.masterFormId
                //   );
                this.masterFormId = startBlock.settings.masterFormId;
              }
            }
          }
          this.generatePDF = startBlock.settings.generatePDF ? 1 : 0;
          this.documentRequired = startBlock.settings.documentRequired || false;
          if (startBlock.settings.formEditAccess) {
            if (startBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = startBlock.settings.formEditControls;
            }
          }
          if (startBlock.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = startBlock.settings.formSecureControls;
          }

          if (startBlock.settings.fileSettings) {
            if (startBlock.settings.fileSettings.fileCheckList.length) {
              let documentCheckList =
                startBlock.settings.fileSettings.fileCheckList;
              documentCheckList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.docWarning = startBlock.settings.fileSettings.partial;
              // if (documentCheckList.length) {
              //   documentCheckList.forEach((row) => {
              //     if (row.required && !row.attach) {
              //       this.docWarning = true;
              //     }
              //   });
              // }

              this.checkList = documentCheckList;
            }
          } else {
            this.checkList = [];
          }
        }
      } else {
        // this.showHistoryStepper(
        //   this.selectedProcess.processId,
        //   this.selectedProcess.id
        // );
        this.getFormDetails();
        if (
          this.selectedNode === "inbox" ||
          this.selectedNode === "common" ||
          this.selectedNode === "payment"
        ) {
          this.formEditAccess = false;
          let wflowJson = JSON.parse(this.workflow.flowJson);

          let actionBlock = wflowJson.blocks.find(
            (block) => block.id === this.selectedProcess.activityId
          );
          if (actionBlock) {
            if (actionBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = actionBlock.settings.formEditControls;
            }
            if (actionBlock.settings.formVisibilityAccess) {
              this.formVisibilityAccess = true;
              this.formSecureControls = [];
            } else {
              this.formVisibilityAccess = false;
              this.formSecureControls = actionBlock.settings.formSecureControls;
            }

            if (
              wflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
            ) {
              let requestNumberFormat = JSON.parse(
                wflowJson.settings.general.processNumberPrefix
              );
              this.readonlyControls = [];
              let requestColumn = requestNumberFormat.filter(
                (row) => row.key === "formColumn"
              );
              if (requestColumn.length) {
                requestColumn.forEach((row) => {
                  this.readonlyControls.push(row.value);
                  if (this.formEditControls.indexOf(row.value) > -1) {
                    this.formEditControls.splice(
                      this.formEditControls.indexOf(row.value),
                      1
                    );
                  }
                });
              }
            }

            if (actionBlock.settings.fileSettings) {
              if (actionBlock.settings.fileSettings.fileCheckList.length) {
                let documentCheckList =
                  actionBlock.settings.fileSettings.fileCheckList;
                documentCheckList.forEach((row) => {
                  row.uid = this.$nano.id();
                  row.attach = false;
                });
                this.docWarning = actionBlock.settings.fileSettings.partial;
                // if (documentCheckList.length) {
                //   documentCheckList.forEach((row) => {
                //     if (row.required && !row.attach) {
                //       this.docWarning = true;
                //     }
                //   });
                // }
                this.checkList = documentCheckList;
              }
              wflowJson.rules.forEach((rule) => {
                if (rule.fromBlockId === this.selectedProcess.activityId) {
                  if (this.actions.indexOf(rule.proceedAction) < 0) {
                    this.actions.push(rule.proceedAction);
                    this.processActionsAddOn.push({
                      action: rule.proceedAction,
                      remarks: rule.remarks || false,
                      passwordAccess: rule.passwordAccess || false,
                      confirm: rule.confirm || false,
                    });
                  }
                }
              });
            } else {
              this.checkList = [];
            }
          }
        } else {
          this.formEditAccess = true;
        }
        if (this.selectedProcess.flowStatus === "1") {
          let wflowJson = JSON.parse(this.workflow.flowJson);

          let actionBlock = wflowJson.blocks.find(
            (block) => block.id === this.selectedProcess.activityId
          );
          if (actionBlock) {
            if (actionBlock.settings.reopen) {
              this.actions = [];
              this.actions.push("Reopen");
            }
          }
        }
        this.initiateProcess = false;
        this.getModel(this.selectedProcess.processId);
        let origin = location.origin;
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          this.dataValidation(this.selectedProcess.processId, false);
        }

        if (
          origin === "https://edmsuat.sobhaapps.com" &&
          this.workflow.id === 55
        ) {
          this.formEditAccess = true;
        }
        this.loadProcessLinks();
      }
      if (this.workflow.wFormId) {
        this.formId = String(this.workflow.wFormId);
        await this.getForm(initiate, this.workflow.wFormId);
        // this.form = JSON.parse(this.wform.formJson);
        // if (!initiate) {
        //   if (this.form.settings.general.layout === "CARD") {
        //     this.form.settings.general.layout = "TAB";
        //   }
        // }
      }
    },

    async getFormDetails() {
      this.$store.commit("showLoadingBarPage");
      let id = this.selectedProcess.requestNo;
      const { error, payload } = await report.getWorkflowData(
        this.selectedProcess.id,
        {
          filterBy: [
            {
              groupCondition: "",
              filters: [
                {
                  criteria: "requestNo",
                  condition: "IS_EQUALS_TO",
                  value: id,
                },
              ],
            },
          ],
          currentPage: 1,
          itemsPerPage: 10,
          mode: "",
        }
      );
      const { data } = payload;
      this.$store.commit("hideLoadingBarPage");
      let formList = data || [];
      if (formList[0].value[0].formData) {
        let keys = Object.keys(formList[0].value[0].formData.fields);
        let newValues = {};
        keys.forEach((key) => {
          newValues[key] = this.validateTypeValue(
            formList[0].value[0].formData.fields[key]
          );
        });
        this.requestFormData = formList;
        this.formModel = formList[0].value[0].formData.fields;
      }
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.workflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    async getAttachments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;

      if (!workflowId && !processId) {
        workflowId = this.workflow.id;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          //clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
        let origin = location.origin;
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          this.formCheckList = true;
        }
      }

      this.$emit("attachmentLength", this.attachmentList.length);
    },

    async getForm(initiate) {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getForm(this.formId);
      //this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.wform = payload;

        this.form = JSON.parse(this.wform.formJson);
        if (!initiate) {
          if (this.form.settings.general.layout === "CARD") {
            this.form.settings.general.layout = "TAB";
          }
        }

        //if (initiate) {
        let formJson = JSON.parse(this.wform.formJson);
        const panels = [...formJson.panels, ...formJson.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }

        fields.forEach((field) => {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            this.formFields.push({
              id: this.$nano.id(),
              label: field.label || field.type,
              value: field.id,
              type: field.type,
              settings: field.settings,
            });
          }
        });
        this.sameFormFields = [];

        this.mainFormFields.forEach((main, index) => {
          let indexFields = this.formFields.findIndex((sub) => {
            return main.label === sub.label;
          });

          if (indexFields > -1) {
            // console.log(main.type, this.formFields[indexFields].type);
            if (
              main.type === "TABLE" &&
              this.formFields[indexFields].type === "TABLE"
            ) {
              let subColumns =
                this.formFields[indexFields].settings.specific.tableColumns;
              let mainColumns = main.settings.specific.tableColumns;
              // console.log(mainColumns, "mainColumns");
              // console.log(subColumns, "subColumns");
              let values = [];
              mainColumns.forEach((mColumn) => {
                let indexColumn = subColumns.findIndex((subColumn) => {
                  return mColumn.label === subColumn.label;
                });
                // console.log(indexColumn, "indexColumn");
                if (indexColumn > -1) {
                  values.push({
                    id: this.$nano.id(),
                    mainColumn: mColumn.id,
                    subColumn: subColumns[indexColumn].id,
                  });
                }
              });
              let rows = [];
              this.mainFormModel[main.value].forEach((row) => {
                let tableValue = {};
                for (let key in row) {
                  let hasName = values.find((item) => item.mainColumn === key);
                  if (hasName) {
                    this.$set(
                      tableValue,
                      [hasName.subColumn],
                      row[hasName.mainColumn]
                    );
                  }
                }
                if (tableValue) {
                  rows.push(tableValue);
                }
              });
              // console.log(rows);
              this.sameFormFields.push({
                id: this.$nano.id(),
                index: indexFields,
                label: main.label,
                name: this.formFields[indexFields].value,
                value: rows,
                mainIndex: index,
              });
            } else {
              this.sameFormFields.push({
                id: this.$nano.id(),
                index: indexFields,
                label: main.label,
                name: this.formFields[indexFields].value,
                value: this.mainFormModel[main.value],
                type: main.type,
                mainIndex: index,
              });
            }
          }
        });
        //}
      }
    },

    signLink(id) {
      return `${process.env.VUE_APP_API_URL}/workflow/signView/${this.$store.state.session.tenantId}/${this.workflow.id}/${id}`;
    },

    itemLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (
        this.selectedNode === "inbox" ||
        this.selectedNode === "common" ||
        this.selectedNode === "payment"
      ) {
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net"
        ) {
          return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}`;
        } else if (origin === "https://trial.ezofis.com") {
          return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=all`;
        } else {
          return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.itemModel.name}&action=all`;
        }
      } else {
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net"
        ) {
          return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}`;
        } else if (origin === "https://trial.ezofis.com") {
          return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=download,print`;
        } else {
          return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}&action=download,print`;
        }
      }
    },

    openFile(fileObj) {
      if (!fileObj.loading) {
        this.selectedFile = fileObj;
        let origin = location.origin;
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net"
        ) {
          window.open(this.fileLink());
        } else {
          this.fileSheet = true;
        }
      } else {
        this.$alert.info("Please wait to complete the uploading");
      }
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    closeMetaDataEditModal() {
      this.showMetaDataEditModal = false;
      this.$refs.attachments.value = null;
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        // this.attachmentSheet = true;
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.displayInternalPublic = false;
        if (!this.portal) {
          let wflowJson = JSON.parse(this.workflow.flowJson);
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );
          if (startBlock) {
            if (startBlock.settings.initiateBy.indexOf("PORTAL") > -1) {
              this.displayInternalPublic = true;
            }
          }
          this.fileNames = [];
          this.fileNames = this.attachmentList
            .filter((item) => {
              let extension = item.name.split(".").pop().toUpperCase();

              return extension.toLowerCase() === "pdf";
            })
            .map((item) => {
              return {
                id: this.$nano.id(),
                label: item.name,
                value: item.id,
              };
            });
        }
        // this.commentsSheet = true;
        this.showCommentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.historyProcessId = this.selectedProcess.processId;
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.workflow.id}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.addTask();
        }
      } else if (tab === "MAIL") {
        this.getMailTransactions();
        this.mailTransactionSheet = true;
      } else if (tab === "FORM_ENTRY_HISTORY") {
        this.showFormEntryHistoryModal = true;
      }
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    validateType(key) {
      let fieldType = "";
      if (this.panels) {
        if (this.panels.length) {
          this.panels.forEach((panel) => {
            if (!panel.fields.length) {
              return;
            }
            let formField = panel.fields.find((field) => field.id === key);
            if (formField) {
              fieldType = formField.type;
            }
          });
        }
      }
      return fieldType;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    async getFileData(file, data) {
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });
      //this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      if (file.fileData) {
        this.fileData = file.fileData;
      } else {
        this.fileData = payload;
      }
      if (data !== "file") this.fileDetailsSheet = true;
    },

    async sendCommentsToJira(commentsText, issueId, commentId) {
      this.$store.commit("showLoadingBar");
      if (this.jiraCommentAction === 1) {
        let resourceIDFieldId = this.formFields.find(
          (field) => field.label === "Resource ID"
        )?.value;
        commentsText =
          this.selectedProcess.formData.fields[resourceIDFieldId] +
          "," +
          commentsText;
      }
      commentsText = `${this.$store.state.session.firstName} ${this.$store.state.session.lastName}: ${commentsText}`;
      let input = {
        cloudIntegrationId: this.jiraAccounts[0].id,
        url: this.selectedProcess.jiraIssueInfo.self,
        data_onpremise: {
          body: commentsText,
        },
        issueId: issueId,
        ezCommentId: commentId,
      };

      const { error, payload } = await integration.insertJiraIssueComments(
        input
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error("error posting comments to jira");
        let msg = JSON.parse(error);
        this.errorMessage = msg.errorMessages;
        this.errorModal = true;
        return;
      }
      console.log(payload);
      this.jiraCommentAction = 1;
    },

    async uploadAttachments(e, fields) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflow.id);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        this.formData = formData;
        if (fields) {
          formData.append("fields", fields);
          this.formData = fields;
        } else {
          formData.append("fields", "");
        }
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
          this.documentLoad = setInterval(() => {
            this.getAttachments();
            clearInterval(this.documentLoad);
          }, 15000);
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.checkListRowIndex) {
              row.attach = true;
              return;
            }
          });
        }
        e.target.value = "";
        if (Object.keys(this.reuploadFile).length) {
          await this.attachmentDelete(this.reuploadFile);
          this.reuploadFile = {};
        }
        //this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        if (fields) {
          this.fileData = JSON.parse(fields);
          formData.append("fields", fields);
        }
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        } else {
          formData.append("filename", filename);
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.$alert.success(`Document attached`);
          let fileName = this.repositoryField.reduce((max, curren) =>
            max.level > curren.level ? max : curren
          );
          let name = this.fileData.find((item) => item.name === fileName.name);

          let loading = false;
          if (ext.toLowerCase() !== "pdf") {
            loading = true;
            this.documentLoadList.push(Number(filesList.fileId));
            this.documentLoad = setInterval(() => {
              let file = this.attachmentList.find(
                (row) => this.documentLoadList.indexOf(row.id) > -1
              );
              if (file) {
                this.documentLoadList.splice(
                  this.documentLoadList.indexOf(file.id),
                  1
                );
                file.loading = false;
              }
              if (this.documentLoadList.length === 0) {
                clearInterval(this.documentLoad);
              }
            }, 10000);
          }

          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: fields ? name.value + "." + ext : filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.checkListRowIndex,
            createdBy: this.$store.state.session.id,
            fileData: this.fileData,
            repositoryId: this.workflow.repositoryId,
            loading: loading,
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.checkListRowIndex) {
                row.attach = true;
                return;
              }
            });
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getMailTransactions() {
      this.mailTransactionList = [];
      this.tabs.find((tab) => {
        if (tab.value === "MAIL") {
          tab.count = 0;
        }
      });
      let filter = { filterBy: [] };
      const { error, payload } = await workflow.getMailTransactions(
        this.workflow.id,
        this.selectedProcess.processId,
        filter
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.mailTransactionList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "MAIL") {
            tab.count = this.mailTransactionList.length;
          }
        });
      }
    },

    checkCommentsOption() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return false;
      } else if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        if (Object.keys(this.selectedProcess).length) {
          if (this.selectedProcess.jiraIssueInfo.id) {
            return true;
          }
        }
        return false;
      }
      return true;
    },

    async uploadWflowAttachments(e) {
      this.fileUploadForControl = false;
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            this.uploadFilename = e.target.files[0].name;
          }
        } else {
          this.uploadFilename = e.target.files[0].name;
        }
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    customCheckUpload() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" &&
        (this.workflow.id === 55 || this.workflow.id === 2092)
      ) {
        if (this.attachmentList.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantGOTO() {
      let origin = location.origin;
      if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        return true;
      }
      return false;
    },

    isTenantGOTOComments() {
      if (Object.keys(this.selectedProcess).length) {
        let origin = location.origin;
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2 &&
          this.selectedProcess.jiraIssueInfo.id
        ) {
          return true;
        }
      }

      return false;
    },

    toggleWidth() {
      this.formWidth = this.formWidth === "100vw" ? "50vw" : "100vw";
    },

    clearAttach() {
      this.selectedFileForAttach = 0;
      this.attachComment = false;
    },

    async getComments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (!workflowId && !processId) {
        workflowId = this.workflow.id;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }

      this.$emit("commentsLength", this.commentsList.length);
    },

    async saveComment() {
      if (this.commentText) {
        if (this.selectedProcess.processId) {
          if (
            (origin === "https://app.ezofis.com1" ||
              origin === "https://appuat.ezofis.com") &&
            this.$store.state.session.tenantId === 2 &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.commentText,
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.commentText,
                showTo: this.portal ? 1 : this.commentAction,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                [payload]
              );
            }
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
            fileIds: this.attachComment ? [this.selectedFileForAttach] : [],
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            (origin === "https://app.ezofis.com1" ||
              origin === "https://appuat.ezofis.com") &&
            this.$store.state.session.tenantId === 2 &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.advancedCommentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.advancedCommentText,
                showTo: this.portal ? 1 : this.commentAction,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
            fileIds: this.attachComment ? [this.selectedFileForAttach] : [],
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
        }
        if (this.actionComments) {
          this.processRequest(false, false);
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (origin === "https://trial.ezofis.com") {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
        } else {
          let action = "";
          if (
            this.selectedNode === "inbox" ||
            this.selectedNode === "common" ||
            this.selectedNode === "payment"
          ) {
            action = "&action=all";
          }
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              action = "";
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
        }
      }
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    closeJiraIssueSheet() {
      this.issueName = "";
      this.projectKey = "";
      this.issueType = "";
      this.issueDescription = "";
      this.issueEpicValue = "";
      this.issueAssignee = "";
      this.issueDescriptionTable = false;
      this.issueDescriptionFields = [];
      this.jiraIssueSheet = false;
    },

    loadProcessLinks() {
      this.getComments();
      this.getAttachments();
      this.getProcessSignature();
      this.getMailTransactions();
    },

    async getProcessSignature() {
      this.signList = [];
      const { error, payload } = await workflow.getProcessSignature(
        this.workflow.id,
        this.selectedProcess.processId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signList = payload;
    },

    async attachmentDelete(file) {
      if (!file.fileControl) {
        if (file.initiate) {
          if (this.repositoryDetails.fieldsType === "STATIC") {
            const { error } = await uploadAndIndex.deleteIndexedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          } else {
            const { error } = await uploadAndIndex.deleteUploadedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          }
          this.attachmentList = this.attachmentList.filter(
            (row) => row.id !== file.id
          );
          this.checkList.forEach((row) => {
            if (row.uid === file.uid) {
              row.attach = false;
            }
          });
        } else {
          const { error } = await repository.fileDelete(
            this.workflow.repositoryId,
            file.id
          );
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.getAttachments();
        }
      } else {
        let field = this.workflowModel[file.fieldId];
        if (field.length) {
          let hasField = field.findIndex((item) => {
            return item.fileId === file.id;
          });
          console.log(hasField);
          if (hasField >= 0) {
            this.workflowModel[file.fieldId].splice(hasField, 1);
          }

          let hasValue = this.formUploadFiles.findIndex((item) => {
            return item.fileId === file.id && item.fileId === file.fileId;
          });
          console.log(hasValue);
          if (hasValue >= 0) {
            this.formUploadFiles.splice(hasField, 1);
          }
          if (file.control.length) {
            file.control.forEach((item) => {
              this.workflowModel[item] = "";
            });
          }
        }
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.fileControl;
        });
        console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    async saveProcessSignature(request) {
      let input = {
        signBinary: this.signImg.split(",")[1],
        fileType: "png",
      };
      const { error } = await workflow.saveProcessSignature(
        this.workflow.id,
        request.processId,
        request.transactionId,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signImg = "";
    },

    async getAppConnection() {
      this.jiraConnect = false;
      const { error, payload } = await integration.getJiraConnection({
        filterBy: [],
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      payload.forEach((row) => {
        if (row.cloudName === "jira") {
          this.jiraConnect = true;
          this.jiraAccounts.push(row);
          //this.getJiraUsers();
        }
      });
    },

    async createJiraIssue() {
      this.issueRef = {};
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      let transactionId = [];
      let mlPrediction = "";
      let descriptionTable = "";
      let totalSavings = 0;

      this.groupKey.forEach((group) => {
        if (group.checked) {
          transactionId = [];
          mlPrediction = group.name;
          return;
        }
      });
      if (mlPrediction === "") {
        this.workflowRequestList.forEach((process) => {
          if (process.checked) {
            transactionId.push(process.transactionId);
          }
        });

        if (this.issueDescriptionTable) {
          descriptionTable = "||Action Taken (Mandatory)";
          this.issueDescriptionFields.forEach((field) => {
            descriptionTable += "||" + field;
          });
          descriptionTable += "||\n";
          this.workflowRequestList.forEach((process) => {
            if (process.checked) {
              descriptionTable += "|| ";
              this.issueDescriptionFields.forEach((field) => {
                let fieldId = this.formFields.find(
                  (row) => row.label === field
                )?.value;
                if (field === "Savings") {
                  if (process.formData.fields[fieldId]) {
                    descriptionTable +=
                      "||" +
                      Number(process.formData.fields[fieldId]).toFixed(2);
                    totalSavings += Number(process.formData.fields[fieldId]);
                  } else {
                    descriptionTable += "|| ";
                  }
                } else {
                  if (process.formData.fields[fieldId]) {
                    descriptionTable += "||" + process.formData.fields[fieldId];
                  } else {
                    descriptionTable += "|| ";
                  }
                }
              });
              descriptionTable += "||\n";
            }
          });
          descriptionTable +=
            "Potential Savings: " + totalSavings.toFixed(2) + "\n";
        }
      }
      //customfield_12130: this.issueEpicValue,
      let input = {};
      if (this.jiraAccounts[0].email) {
        input = {
          fields_cloud: {
            fields: {
              summary: this.issueName,
              project: {
                key: this.projectKey,
              },
              issuetype: {
                name: this.issueType,
              },
              description: {
                content: [
                  {
                    content: [
                      {
                        text: this.issueDescription + descriptionTable,
                        type: "text",
                      },
                    ],
                    type: "paragraph",
                  },
                ],
                type: "doc",
                version: 1,
              },
            },
          },
          cloudIntegrationId: this.jiraAccounts[0].id,
        };
      } else {
        input = {
          fields_onpremise: {
            fields: {
              summary: this.issueName,
              project: {
                key: this.projectKey,
              },
              issuetype: {
                name: this.issueType,
              },
              description: this.issueDescription + descriptionTable,
              customfield_14635: {
                value: "No",
              },
              customfield_15136: {
                value: "Maintenance",
              },
            },
          },
          cloudIntegrationId: this.jiraAccounts[0].id,
          workflowId: this.workflow.id,
          transactionId: transactionId,
          mlPrediction: mlPrediction,
          assignee: this.issueAssignee ? { name: this.issueAssignee } : {},
          filterBy: this.filterBy,
        };
      }
      //console.log(input);
      this.$store.commit("showLoadingBar");
      const { error, payload } = await integration.createJiraIssue(input);
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.warning("error creating issue in jira");
        let msg = JSON.parse(error);
        console.log(msg);
        this.errorMessage = msg.errorMessages;
        // let msgContent = JSON.parse(msg.Message);
        // this.errorMessage = msgContent.errors;
        this.errorModal = true;
        return;
      }
      if (mlPrediction) {
        this.successModal = true;
      } else {
        if (payload) {
          this.issueRef = JSON.parse(payload);
          this.issueRef.self = this.getJIRALink(this.issueRef);
          this.$alert.success(`Issue Created in JIRA`);
          this.jiraConfirmModal = true;
        } else {
          this.$alert.warning("error creating issue in jira");
          return;
        }
      }
      this.closeJiraIssueSheet();
    },

    async insertJiraIssueComment() {
      let referenceIdFieldId = "",
        remarksFieldId = "";
      referenceIdFieldId = this.formFields.find(
        (field) => field.label === "Reference ID"
      )?.value;
      remarksFieldId = this.formFields.find(
        (field) => field.label === "Remarks"
      )?.value;
      if (
        this.workflowFormModel[referenceIdFieldId] &&
        this.workflowFormModel[remarksFieldId]
      ) {
        this.$store.commit("showLoadingBar");
        let input = {};
        if (this.jiraAccounts[0].email) {
          input = {
            cloudIntegrationId: this.jiraAccounts[0].id,
            url: this.workflowFormModel[referenceIdFieldId],
            data_cloud: {
              body: this.workflowFormModel[remarksFieldId],
            },
          };
        } else {
          input = {
            cloudIntegrationId: this.jiraAccounts[0].id,
            url: this.workflowFormModel[referenceIdFieldId],
            data_onpremise: {
              body: this.workflowFormModel[remarksFieldId],
            },
          };
        }

        const { error, payload } = await integration.insertJiraIssueComments(
          input
        );
        this.$store.commit("hideLoadingBar");
        if (error) {
          this.$alert.error(error);
          return;
        }
        console.log(payload);
      }
    },

    async getJiraUsers() {
      this.issueAssigneeUsers = [];
      const { error, payload } = await integration.getJiraUsers({
        cloudIntegrationId: this.jiraAccounts[0].id,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        payload.forEach((user) => {
          this.issueAssigneeUsers.push({
            id: user.key,
            label: user.displayName,
            value: user.name,
          });
        });
      }
    },

    validateRequest(action) {
      this.saveAction = action;
      if (this.itemModel.itemId) {
        this.processRequest();
      } else if (!this.formView) {
        this.proceedSubmission();
      }
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        let origin = location.origin;
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2
        ) {
          if (this.saveAction === "Assign" && this.internalForward.proceed) {
            this.processForwardGroupAction = false;
            this.processForwardModal = true;
            return;
          }
        }
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }
      }

      if (addOnCheck) {
        let actionAddOn = this.processActionsAddOn.find(
          (row) => row.action === this.saveAction
        );
        if (actionAddOn) {
          if (Object.keys(actionAddOn).length) {
            this.actionRemarks = actionAddOn.remarks;
            this.actionPassword = actionAddOn.passwordAccess;
            this.actionConfirm = actionAddOn.confirm;
            if (this.actionPassword) {
              this.actionPasswordConfirm = true;
              return;
            }
            if (this.actionRemarks) {
              this.actionComments = true;
              this.advancedCommentsSheet = true;
              return;
            }
            if (this.actionConfirm) {
              this.actionConfirmAlert = true;
              return;
            }
          }
        }
      }

      let inputData = {};
      let origin = location.origin;

      if (this.userReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "UserReply";
      } else if (this.toRequester && this.saveAction === "To Requester") {
        this.saveAction = "ToInitiator";
      }
      if (this.customReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "Reply";
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      }

      let formUpload = [];
      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];
          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });
          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }

          delete this.workflowFormModel[field.value];
        }
      });

      if (this.formId) {
        if (
          (origin === "https://app.ezofis.com1" ||
            origin === "https://appuat.ezofis.com") &&
          this.$store.state.session.tenantId === 2 &&
          this.saveAction === "Rollback"
        ) {
          if (this.workflow.id === 1) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        } else if (
          origin === "http://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.workflow.id === 6) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        }
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review:
            this.saveAction === "Save" || this.saveAction === "CustomVerify"
              ? ""
              : this.saveAction,
          formData: this.selectedProcess.formData
            ? {
                formId: this.formId,
                formEntryId: this.selectedProcess.formData.formEntryId,
                fields: this.workflowFormModel,
                formUpload: formUpload,
              }
            : {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else if (this.itemModel) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      // console.log(inputData);
      if (!this.saveOnly) {
        this.$store.commit("showLoadingBarSave");
      }
      const { error } = await workflow.processTransaction(inputData);
      if (!this.saveOnly) {
        this.$store.commit("hideLoadingBarSave");
      }

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(this.selectedProcess);
      }
      if (this.saveOnly) {
        this.saveOnly = false;
        //this.$alert.info(`${this.selectedProcess.requestNo} data saved, awaiting your action to submit`);
        //this.$emit("paymentTable");
      } else {
        if (inputData.review === "") {
          this.$alert.info(
            `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
          );
        } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
          this.$alert.danger(
            `${this.selectedProcess.requestNo} Request Rejected Successfully`
          );
        } else {
          this.$alert.success(
            `${this.selectedProcess.requestNo} Request Processed`
          );
        }
        this.forwardUsers = [];
        this.forwardGroups = [];
        this.customReply = false;
        this.userReply = false;
        this.toRequester = false;
        //this.selectedProcess = {};

        this.closeProcessSheet();
      }
      if (this.fileControlDeleteFile.length) {
        this.fileControlDeleteFile.forEach((file) => {
          this.fileControlDeletedFile(file);
        });

        this.fileControlDeleteFile = [];
      }
    },

    checkRequiredDocument() {
      if (this.saveAction === "Save" || this.saveAction === "Force Close") {
        this.proceedSubmission();
      } else {
        if (this.documentRequired && this.attachmentList.length === 0) {
          this.$alert.warning(
            "A minimum of one document is required for submission"
          );
          this.saveAction = "";
          this.workflowFormModel = {};
          return;
        }
        let docRequired = this.checkList.filter(
          (row) => row.required && !row.attach
        );

        if (docRequired.length) {
          if (this.docWarning) {
            this.alertModal = true;
          } else {
            this.$alert.warning("Mandatory documents required");
            this.saveAction = "";
            this.workflowFormModel = {};
          }
        } else {
          this.proceedSubmission();
        }
      }
    },

    proceedSubmission() {
      this.alertModal = false;
      if (this.initiateProcess) {
        this.initiateRequest();
      } else {
        this.processRequest();
      }
    },

    closeAlertModal() {
      this.alertModal = false;
      this.saveAction = "";
      this.workflowFormModel = {};
    },

    getJiraIssueInfo(data) {
      if (data.jiraIssueInfo.id) {
        return {
          "Ref# Id": data.jiraIssueInfo.id,
          Key: data.jiraIssueInfo.key,
          Link: data.jiraIssueInfo.self
            ? this.getJIRALink(data.jiraIssueInfo)
            : "",
          Assignee: data.jiraIssueInfo.assignee,
          "Issue Status": data.jiraIssueInfo.issueStatus,
        };
      }
    },

    async groupProcessRequest() {
      let transactionId = [];
      let mlPrediction = "";
      this.workflowRequestList.forEach((process) => {
        if (process.key === this.groupActionKey && process.checked) {
          transactionId.push(process.transactionId);
        }
      });
      this.groupKey.forEach((group) => {
        if (group.checked) {
          transactionId = [];
          mlPrediction = group.name;
          return;
        }
      });
      let input = {
        workflowId: this.workflow.id,
        transactionId: transactionId,
        review: this.saveAction === "Save" ? "" : this.saveAction,
        fields: this.workflowFormModel,
        mlPrediction: mlPrediction,
        comments: this.commentText,
        userIds: this.forwardUsers,
        groupIds: this.forwardGroups,
        filterBy: this.filterBy,
      };
      //console.log(input);
      //this.$store.commit("showLoadingBar");
      const { error } = await workflow.groupRequestAction(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      await this.insertJiraIssueComment();
      this.saveAction = "";
      this.workflowFormModel = {};
      this.forwardUsers = [];
      this.forwardGroups = [];
      this.$alert.success(`Group Request Processed`);
      this.closeRequestActionModal();
      this.$emit("refresh", this.workflow.id);
    },

    async initiateRequest() {
      let uploadedIdList = [];
      if (this.attachmentList.length) {
        this.attachmentList.forEach((file) => {
          uploadedIdList.push(file.id);
        });
      }

      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];
          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });
          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }

          delete this.workflowFormModel[field.value];
        }
      });

      let inputData = {
        workflowId: this.workflow.id,
        review: this.saveAction === "Save" ? "" : this.saveAction,
        comments: this.commentsList,
        formData: {
          formId: this.formId,
          fields: this.workflowFormModel,
          formUpload: formUpload,
        },
        fileIds: uploadedIdList,
        task: this.taskEntryId,
        hasFormPDF: this.generatePDF,
        prefix: this.requestPrefix,
        mlPrediction: this.mlPrediction,
      };

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");
      this.saveAction = "";
      this.workflowFormModel = {};
      this.alertModal = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(payload);
      }
      this.$alert.success(`${payload.requestNo} Request Initiated`);
      if (this.mainProcessId) {
        this.addSubWorkflowProcess(payload.processId);
      } else {
        this.$emit("update:subProcessId", payload.processId);
      }
      this.closeProcessSheet();
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });

      data.forEach((key) => {
        // console.log(key);
        let hasName = this.formFields.find(
          (item) => lowerCase(item.label) === lowerCase(key.name)
        );
        // console.log(hasName);
        if (hasName && key.value) {
          this.workflowModel[hasName.value] = key.value;
        }
      });

      if (this.fileUploadForControl) {
        this.metaDataModal = modal;
        let input = this.fileUploadInput;
        this.uploadFilesFromForm(
          input.files,
          input.control,
          input.fieldId,
          JSON.stringify(data)
        );
      } else {
        this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
      }
    },

    attachTemplate(uid) {
      this.checkListRowIndex = uid;
      this.$refs.attachments.click();
    },

    attachmentFilterCheckList(name) {
      return this.attachmentList.filter(
        (row) =>
          row.name.substring(0, row.name.lastIndexOf(".")) === name ||
          row.name.substring(0, row.name.lastIndexOf(".")).split("_")[0] ===
            name
      );
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    showMailAttachments(mail) {
      this.mailAttachmentList = [];
      let files = mail.attachmentJson.split(",");
      files.forEach((file) => {
        let filename = file.split("\\").pop();
        this.mailAttachmentList.push({
          id: this.$nano.id(),
          mailId: mail.id,
          name: filename,
          createdAt: mail.createdAt,
        });
      });
      this.mailAttachmentSheet = true;
    },

    openAttachmentFile(file) {
      let link = `${process.env.VUE_APP_API_URL}/client/viewAttachment/${this.$store.state.session.tenantId}/${file.mailId}/${file.name}`;
      window.open(link, "_blank");
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    async getTaskFormEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getFormEntry(formId, entryId);
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    async getTaskList() {
      this.taskEntries = [];
      let taskFilterBy = [];
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];
      let formFields = [];
      for (const panel of panels) {
        formFields.push(...panel.fields);
      }
      let taskOwnerField = formFields.find(
        (field) => field.label === "Task Owner"
      );
      if (taskOwnerField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskOwnerField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      let taskAssignField = formFields.find(
        (field) => field.label === "Task Assign"
      );
      if (taskAssignField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskAssignField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getTaskEntries(
        this.selectedWorkflow.id,
        this.selectedProcess.processId,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "createdBy",
                  condition: "IS_EQUALS_TO",
                  value: this.$store.state.session.id,
                },
              ],
            },
            ...taskFilterBy,
          ],
        }
      );
      //this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.taskList = payload;
        payload.forEach((row) => {
          let taskName = "",
            taskAssign = "",
            startDate = "",
            endDate = "",
            priority = "",
            status = "";
          formFields.forEach((field) => {
            switch (field.label) {
              case "Task Name":
                taskName = row.entryInfo[0][field.id];
                break;
              case "Task Assign":
                taskAssign = row.entryInfo[0][field.id];
                break;
              case "Start Date":
                startDate = row.entryInfo[0][field.id];
                break;
              case "End Date":
                endDate = row.entryInfo[0][field.id];
                break;
              case "Priority":
                priority = row.entryInfo[0][field.id];
                break;
              case "Task Status":
                status = row.entryInfo[0][field.id];
                break;
            }
          });
          this.taskEntries.push({
            id: row.entryInfo[0].itemid,
            name: taskName,
            assigned: taskAssign,
            startDate: startDate,
            endDate: endDate,
            priority: priority,
            status: status,
          });
        });
      }
    },

    async insertTaskEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error } = await workflow.insertTaskEntry({
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        transactionId: this.selectedProcess.transactionId,
        wFormId: formId,
        formEntryId: entryId,
      });
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getTaskList();
    },

    addTaskEntry(formId, entryId) {
      if (this.selectedProcess.processId) {
        this.insertTaskEntry(formId, entryId);
      } else {
        this.taskEntryId.push({
          formId: formId,
          formEntryId: entryId,
        });
        this.getTaskFormEntry(formId, entryId);
      }
    },

    addTask() {
      this.selectedTask = {};
      this.taskMode = "NEW";
      this.addTaskSheet = true;
    },

    viewTask(id) {
      let filterTask = this.taskList.filter((row) => row.formEntryId === id);
      if (filterTask.length) {
        this.selectedTask = filterTask[0].entryInfo[0];
        this.selectedTask = {
          id: filterTask[0].formEntryId,
          ...filterTask[0].entryInfo[0],
        };
      }
      this.taskMode = "EDIT";
      this.addTaskSheet = true;
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },

    async uploadFile(e) {
      const file = e.target.files[0];
      if (
        file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const formData = new FormData();
        formData.append("formId", this.masterFormId);
        formData.append("file", file);
        try {
          this.$store.commit("showLoadingBarSave");
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/form/uploadMasterFile`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBarSave");
          const { status, data } = response;
          if (status === 200 && data)
            this.$alert.success(`Data imported successfully`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }

        this.dataImported = true;
        e.target.value = "";
      } else {
        this.$alert.error("Please upload a csv/excel file");
      }
    },

    async addSubWorkflowProcess(subProcessId) {
      let input = {
        mainWorkflowId: this.mainWorkflowId,
        subWorkflowId: this.workflowId,
        mainProcessId: this.mainProcessId,
        subProcessId: subProcessId,
        subProcessStatus: 0,
        review: "",
      };
      const { error } = await workflow.addSubWorkflow(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.updateSubWorkflowProcess();
    },

    getModel(processId) {
      this.formModel = {};
      this.itemModel = {};
      if (
        this.selectedProcess.processId == processId &&
        this.selectedProcess.id == this.workflow.id
      ) {
        let currentStage =
          this.selectedProcess.stage || this.selectedProcess.stageName;
        this.headerTitle = `${this.selectedProcess.requestNo} - ${currentStage}`;
        if (this.selectedProcess.formData) {
          let keys = Object.keys(this.selectedProcess.formData.fields);
          let newValues = {};
          keys.forEach((key) => {
            newValues[key] = this.validateTypeValue(
              this.selectedProcess.formData.fields[key]
            );
          });
          // console.log("newValues", newValues);
          this.formModel = newValues;
        } else if (this.selectedProcess.itemData) {
          this.itemModel = this.selectedProcess.itemData;
        }
      }
    },

    async dataValidation(processId, modal = true) {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await custom.armgroupMLValidation({
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: processId,
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.dataValidationValues = payload;
        if (modal) {
          this.dataValidationModal = true;
        }
      } else {
        this.dataValidationValues = [];
        if (modal) {
          this.$alert.info("Data not found");
        }
      }
    },

    async documentMerge() {
      let fileIds = [];
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() === "pdf") {
          fileIds.push(item.id);
        }
      });
      let input = {
        ids: fileIds,
      };
      const { error } = await workflow.documentMerge(
        this.workflow.id,
        this.selectedProcess.processId,
        this.selectedProcess.transactionId,
        this.workflow.repositoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Document merge process initiated");
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (
                this.repositoryDetails.fieldsType === "STATIC" &&
                this.workflow.initiatedBy === "FORM"
              ) {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";

        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/2`;
        } else {
          link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async updateSubWorkflowProcess() {
      const { error, payload } = await transaction.updateSubWorkflowProcess(
        this.mainWorkflowId,
        this.$store.state.session.tenantId,
        this.workflowId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
    },

    async uploadFilesFromForm(files, control, fieldId, fields) {
      this.formUploadFiles = [];
      const formData = new FormData();
      let formFields = [];
      if (control && control.length) {
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === field);
          if (name) {
            formFields.push(`${name.label}, ${name.type}`);
          }
        });
      }
      if (files.length) {
        // this.$store.commit("showLoadingBarSave");
        for (let file of files) {
          if (file.name && !file.error) {
            formData.append("repositoryId", this.workflow.repositoryId);
            formData.append("file", file);
            formData.append("filename", file.name);
            if (formFields.length) {
              formData.append("formFields", JSON.stringify(formFields));
            }
            if (fields) {
              formData.append("fields", fields);
            } else {
              formData.append("fields", "");
            }

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(this.workflowModel, fieldId);
                  let hasFile = this.workflowModel[fieldId].findIndex(
                    (item) => {
                      return item.name === file.name;
                    }
                  );
                  // console.log(hasFile);
                  if (hasFile >= 0) {
                    this.$set(
                      this.workflowModel[fieldId][hasFile],
                      "uploadedPercentage",
                      0
                    );
                    this.workflowModel[fieldId][hasFile].uploadedPercentage =
                      uploadedPercentage;
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }
              file.createdAt = this.$day.newDateTime();
              file.createdBy = this.$store.state.session.email;
              let filesList = JSON.parse(data);
              file.fileId = filesList.fileId;
              file.fieldId = fieldId;
              // if (formFields.length) {
              //   let ocrList = JSON.parse(filesList.OCRfieldlist);
              //   if (Array.isArray(ocrList)) {
              //     ocrList.forEach((hasFile) => {
              //       let fieldId = this.formFields.find(
              //         (item) => item.label === hasFile.name
              //       );
              //       if (fieldId) {
              //         if (hasFile.value) {
              //           this.workflowModel[fieldId.value] = hasFile.value;
              //         }
              //       }
              //     });
              //   }
              // }
            } catch (error) {
              console.error(error);
              this.$alert.error(`error attaching file`);
            }
          }
        }

        let _files = Array.from(files);
        // console.log(_files);
        this.formUploadFiles = [];
        for (let file of _files) {
          // console.log(file.name);
          if (file.name) {
            const base64String = await fileToBase64(file);

            this.formUploadFiles.push({
              name: file.name,
              size: file.size,
              file: base64String,
              fileId: file.fileId,
              createdBy: file.createdBy,
              createdAt: file.createdAt,
              workflowId: this.selectedProcess.id,
              processId: this.selectedProcess.processId,
              fieldId: file.fieldId,
            });
          }
        }
        control.forEach((con) => {
          let index = this.readonlyControls.findIndex((item) => {
            return item === con;
          });
          // console.log(index);
          if (index >= 0) {
            this.readonlyControls.splice(index, 1);
          }
        });
        this.$store.commit("hideLoadingBarSave");
      }
    },

    mandatoryControlsList(value) {
      this.mandatory = value;
    },

    deletedFiles(ids) {
      //this.fileControlDeleteFile = [];
      this.fileControlDeleteFile.push(ids);
    },

    async fileControlDeletedFile(file) {
      this.$store.commit("showLoadingBar");
      let fileIds = [];
      file.forEach((item) => {
        if (item.id) fileIds.push(item.id);
      });
      let input = {
        ids: fileIds,
        formInfo: {
          formId: this.formId,
          formEntryId: this.selectedProcess.formData.formEntryId,
          jsonId: file[0].jsonId,
        },
      };
      const { error } = await repository.fileControlDeleteFile(
        this.workflow.repositoryId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // this.fileControlDeleteFile = [];
    },

    async checkFormValidation() {
      this.hasCheckFormValidate = true;
    },

    async getOCRResult(files, control, fieldId) {
      const formData = new FormData();
      let formFields = [];
      if (control && control.length) {
        control.forEach((con) => {
          this.readonlyControls.push(con);
        });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === field);
          if (name) {
            formFields.push(`${name.label}, ${name.type}`);
          }
        });
        if (files.length) {
          // this.$store.commit("showLoadingBarSave");
          for (let file of files) {
            if (file.name && !file.error) {
              formData.append("repositoryId", this.workflow.repositoryId);
              formData.append("file", file);
              if (formFields.length) {
                formData.append("formFields", JSON.stringify(formFields));
              }

              try {
                const response = await axios({
                  url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                  method: "POST",
                  data: formData,
                  headers: {
                    Token: this.$store.state.identity.token,
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    let uploadedPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // console.log(this.workflowModel, fieldId);

                    let hasFile = this.workflowModel[fieldId].findIndex(
                      (item) => {
                        return item.name === file.name;
                      }
                    );
                    // console.log(hasFile);
                    if (hasFile >= 0) {
                      this.$set(
                        this.workflowModel[fieldId][hasFile],
                        "uploadedPercentage",
                        0
                      );
                      if (uploadedPercentage !== 100) {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = uploadedPercentage;
                      }
                    }
                  }.bind(this),
                });

                const { status, data } = response;
                if (status !== 200) {
                  throw "Invalid response";
                }

                // console.log(data);

                let list = decrypt(
                  data,
                  this.$store.state.identity.key,
                  this.$store.state.identity.iv
                );
                // console.log(list);

                let ocrList = JSON.parse(list);
                let ocr = JSON.parse(ocrList);
                let ocrFile = JSON.parse(ocr);
                // console.log(ocrFile);

                if (Array.isArray(ocrFile)) {
                  ocrFile.forEach((hasFile) => {
                    let fieldId = this.formFields.find(
                      (item) => item.label === hasFile.name
                    );
                    // console.log(fieldId);
                    if (fieldId) {
                      if (hasFile.value) {
                        this.workflowModel[fieldId.value] = hasFile.value;
                      }
                    }
                  });
                }
              } catch (error) {
                console.error(error);
                this.$alert.error(`error on get ocr result`);
              }
            }
            let hasFile = this.workflowModel[fieldId].findIndex((item) => {
              return item.name === file.name;
            });

            // console.log(hasFile);

            if (hasFile >= 0) {
              //this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
            }
          }

          this.$store.commit("hideLoadingBarSave");
          this.uploadFileForControl(files, control, fieldId);
        }
      } else {
        this.uploadFileForControl(files, control, fieldId);
      }
    },

    async uploadFileForControl(files, control, fieldId) {
      this.fileUploadInput = {
        files: files,
        control: control,
        fieldId: fieldId,
      };
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.fileUploadForControl = false;

        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            // this.uploadFilename = files.target.files[0].name;
          }
        } else {
          // this.uploadFilename = files.target.files[0].name;
        }
        this.fileUploadForControl = true;
        this.showMetaDataEditModal = true;
      } else {
        this.uploadFilesFromForm(files, control, fieldId);
      }
    },

    async showHistoryStepper(processId, wId) {
      console.log("called");
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.processHistory(wId, processId);
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        // console.log(payload);
        payload.forEach((row) => {
          if (row.actionStatus !== 2) {
            if (
              this.stageLevel.filter((stage) => stage.id === row.activityId)
                .length === 0
            ) {
              this.stageLevel.push({
                id: row.activityId,
                label: row.stage,
                status: row.status,
              });
              if (
                ((row.actionUser || row.subWorkflowHistory) &&
                  row.status &&
                  row.status !== "Forward") ||
                (row.actionUser === null && !row.subWorkflowHistory)
              ) {
                this.currentStep += 1;
              }
            } else if (row.status) {
              if (
                this.stageLevel.filter(
                  (stage) =>
                    stage.id === row.activityId && stage.status !== row.status
                ).length
              ) {
                this.currentStep += 1;
              } else {
                this.currentStep -= 1;
              }
            } else {
              let index = this.stageLevel.findIndex(
                (stage) => stage.id === row.activityId
              );
              if (index > -1) {
                this.stageLevel.splice(index, this.stageLevel.length - index);
                this.stageLevel.push({
                  id: row.activityId,
                  label: row.stage,
                  status: row.status,
                });
                if (
                  (row.actionUser && row.status && row.status !== "Forward") ||
                  row.actionUser === null
                ) {
                  this.currentStep = index + 1;
                } else {
                  this.currentStep = index;
                }
              }
            }
          }
        });
      }
    },

    attachmentCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = count;
        }
      });
    },

    commentsCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = count;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#process-sheet {
  .list {
    height: 100%;
    border: 1px solid transparent;
    padding: 5px;
    border-radius: 10px;
    background: #dfe6e9;
    // width: 100%;
  }

  .cards {
    height: 100%;
    // border: 1px solid #fff;
    padding: 5px;
    border-radius: 4px;
    background: white;
    border: 1px solid #fff;

    // width: 100%;
  }

  #action-btn.linkActions {
    width: 28px !important;
    height: 28px !important;
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 10px;
    background: $secondary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .workflow-name {
    width: 150px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000 !important;
    font-weight: 500;
    text-align: justify;
  }

  .workflow-name:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }

  #global-search {
    width: 90%;
    height: 26px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0px 12px;
    color: var(--icon-color);
    border: 1px solid var(--divider-color);
    float: right;
    // background-color: var(--body-bg-color);

    .label {
      @extend .text-sm;
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }

  .taskKanbanRow {
    // display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 100%;
  }
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }

  #action-btn.comment-actions {
    height: 28px !important;
    width: 28px !important;
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}

.chip-warning {
  font-weight: 500;
  background-color: var(--component-bg-color-inferior);
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chip-success {
  font-weight: 500;
  background-color: #9bf3b0;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.attachment-label {
  border: 1px solid;
  border-radius: 20px;
  padding: 5px 10px;
  color: $secondary;
  background: $secondary-1;
  font-size: 12px;
  &.ellipsis:hover {
    white-space: normal;
  }
}
</style>

<style lang="scss">
#single-choice-field.actions .options-wrapper {
  padding-top: 8px !important;
}
#single-choice-field.actions.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}

.advance-cmd {
  margin-right: auto;
}

.file-icon {
  width: 18px !important;
  height: 18px !important;
}

.text-area-action {
  border: 1px solid var(--border-color);
  #field-wrapper {
    border: none !important;
    .is-focused {
      border: none;
    }
  }
}

.attach-cmd {
  margin-left: 15px;
  #field-wrapper {
    height: 30px !important;
    .select {
      .value {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .value:hover {
        max-width: none !important;
        overflow: visible;
        white-space: pre;
      }
    }
  }
}

.select-action {
  padding-top: 10px;

  align-items: center;
}

.action-container {
  border-top: 1px solid #cfd5dc;
}

.internal-public {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #cfd5dc;
  padding-top: 10px;
}

.internal-public-margin {
  margin-top: 5px;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.loading {
  animation: spin 2s linear infinite;
}

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;
  }
}

#requestDetails {
  table {
    &.submittedData {
      background: $primary-1;
    }
    &.mainData {
      table-layout: fixed;
    }
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: $primary-11;
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--divider-color);
      }
    }

    .tblHeader {
      color: $primary-11;
      font-weight: 500;
      &.small {
        width: 200px;
      }
    }
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>
